import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm, useFormValidation } from "../../../hooks";
import { dangerNotification } from "../../../redux/slices/ui";

import { EmailInput, LightButton, PrimaryButton, TextInputPreview } from "../../../components"
import { usePacienteIndependizar } from '../hooks/useIndependizar';

export const AsociadoUpdate = ({ asociado, asociadoAnterior, cancelar, idAsociado }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { independizarPacienteMutation: { mutate: independizarPaciente } } = usePacienteIndependizar();

    const { email, onInputChange } = useForm({ email: "" });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'email', default: false}]);

    const handleViewPaciente = () => {
        navigate(`/paciente/view/${idAsociado}`);
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            if(idAsociado !== ""){
                console.log('aca');
                independizarPaciente({ data: { email, id: idAsociado }, asociadoAnterior, onSuccess: handleViewPaciente });
            }else{
                dispatch(dangerNotification('Paciente a independizar no identificado, intenta nuevamente'));
            }
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="row d-flex justify-content-between">
                    <div className="col-md-11 col-lg-3">
                        <TextInputPreview
                            label="Nombre"
                            name="nombre"
                            value={ asociado.nombre }
                        />
                    </div>
                    <div className="col-md-11 col-lg-3">
                        <EmailInput
                            formFieldStatus={ formFields['email'].status }
                            label="Correo electrónico"
                            name="email"
                            onChange={ onInputChange }
                            required={ true }
                            requiredIndicator={ false }
                            setFormFieldValidation={ setFormFieldValidation }
                            value={ email }
                        />
                    </div>
                    <div className="col-md-11 col-lg-3">
                        <LightButton
                            block={true}
                            disabled={formValidationStatus === 'checking'}
                            icon="X"
                            onClick={cancelar}
                            text="Cancelar"
                            type="button"
                        />
                    </div>
                    <div className="col-md-11 col-lg-3">
                        <PrimaryButton
                            block={true}
                            disabled={formValidationStatus === 'checking'}
                            icon="Check"
                            text="Independizar"
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

AsociadoUpdate.propTypes = {
    asociado: PropTypes.any.isRequired,
    asociadoAnterior: PropTypes.string.isRequired,
    cancelar: PropTypes.func.isRequired,
    idAsociado: PropTypes.string.isRequired,
}