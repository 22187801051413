import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useLaboratorio } from '../hooks/useLaboratorio';
import { usePacienteLaboratorio } from '../hooks/usePacienteLaboratorio';
import { useForm, useFormValidation } from '../../../hooks';

import { DateInput, FileInput, LightButton, PrimaryButton, ReactAutocompleteInput, TextAreaInput, TextInputPreview } from '../../../components';

import styles from '../PacienteConsulta.module.css';

export const Laboratorio = () => {

    let { id } = useParams();

    const { laboratorioQuery: { isLoading: isLoadingL, isError: isErrorL, data: laboratorio } } = useLaboratorio();

    const { pacienteLaboratorioQuery: { isLoading: isLoadingPL, isError: isErrorPL, data: pacienteLaboratorio }, createLaboratorioMutation: { mutate: createLaboratorio, isPending } } = usePacienteLaboratorio(id);

    const [resetFields, setResetFields] = useState(moment().unix());

    const [isClearable, setIsClearable] = useState(false);

    const { estudio, fecha, comentarios, archivo, onInputChange, onResetForm } = useForm({ estudio: '', fecha: '', comentarios: '', archivo: null });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation, onResetValidation } = useFormValidation([{field: 'estudio', default: false}, {field: 'fecha', default: false}, {field: 'comentarios', default: true}, {field: 'archivo', default: false}]);

    const handleDownload = (url) => {
        window.open(url, '_blank');
    }

    const handleOnFieldChange = (e) => {
        setIsClearable(true);
        onInputChange(e);
    }

    const handleClearForm = () => {
        setResetFields(moment().unix());
        onResetForm();
        onResetValidation();
        setIsClearable(false);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            createLaboratorio({ data: { estudio, fecha, comentarios, archivo }, id, success:  handleClearForm});
        }else{
            startFormValidation();
        }
    }

    return (
        <>
            {(isLoadingL)
                ? <>Cargando...</>
                : <>
                    {(isErrorL)
                    ? <>Error</>
                    : <>
                        <div className="row">
                            <div className="col">
                                <div className={styles.tabsSubHeader}>Laboratorios</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className={styles.estudioContainer}>
                                    <form onSubmit={onSubmit}>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-6">
                                                <ReactAutocompleteInput
                                                    key={`key_${resetFields}`}
                                                    formFieldStatus={ formFields['estudio'].status }
                                                    label="Estudio"
                                                    name="estudio"
                                                    onChange={ handleOnFieldChange }
                                                    required={true}
                                                    requiredIndicator={true}
                                                    reset={resetFields}
                                                    setFormFieldValidation={ setFormFieldValidation }
                                                    value={estudio}
                                                    values={laboratorio.map(radio => ({ value: radio._id, label: radio.nombre }))}
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <DateInput
                                                    formFieldStatus={ formFields['fecha'].status }
                                                    label="Fecha"
                                                    name="fecha"
                                                    onChange={ handleOnFieldChange }
                                                    required={ true }
                                                    requiredIndicator={ true }
                                                    reset={resetFields}
                                                    setFormFieldValidation={ setFormFieldValidation }
                                                    value={ fecha }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <TextAreaInput
                                                    formFieldStatus={ formFields['comentarios'].status }
                                                    label="Comentarios"
                                                    name="comentarios"
                                                    onChange={ handleOnFieldChange }
                                                    required={ false }
                                                    requiredIndicator={ false }
                                                    setFormFieldValidation={ setFormFieldValidation }
                                                    value={ comentarios }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <FileInput
                                                    disabled={ isPending }
                                                    formFieldStatus={ formFields['archivo'].status }
                                                    label="Archivo"
                                                    name="archivo"
                                                    onChange={ handleOnFieldChange }
                                                    required={true}
                                                    requiredIndicator={true}
                                                    reset={resetFields}
                                                    setFormFieldValidation={ setFormFieldValidation }
                                                    value={ archivo }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-6">
                                                <LightButton
                                                    block={true}
                                                    disabled={isPending || formValidationStatus === 'checking' || !isClearable}
                                                    icon="X"
                                                    marginRight={true}
                                                    marginTop={false}
                                                    onClick={handleClearForm}
                                                    text="Cancelar"
                                                    type="button"
                                                />
                                            </div>
                                            <div className="col-md-12 col-lg-6 mt-md-3 mt-lg-0">
                                                <PrimaryButton
                                                    block={true}
                                                    disabled={isPending || formValidationStatus === 'checking'}
                                                    icon="Check"
                                                    marginTop={false}
                                                    text="Guardar"
                                                    type="submit"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className={styles.tabsSubHeader}>Previos</div>
                            </div>
                        </div>
                        {(isLoadingPL)
                            ? <>Cargando...</>
                            : <>
                                {(isErrorPL)
                                ? <>Error</>
                                : <>
                                    {(pacienteLaboratorio.length > 0)
                                    ? pacienteLaboratorio.map(radio => <div key={radio._id} className={styles.estudioContainer}>
                                        <div className="row">
                                            <div className="col-xs-12 col-md-6">
                                                <TextInputPreview
                                                    label="Estudio"
                                                    name="estudio"
                                                    value={radio.estudio.nombre}
                                                />
                                            </div>
                                            <div className="col-xs-12 col-md-6">
                                                <TextInputPreview
                                                    label="Fecha"
                                                    name="fecha"
                                                    value={radio.fechaFormato}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <TextInputPreview
                                                    label="Comentarios"
                                                    name="comntarios"
                                                    value={radio.comentarios}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <PrimaryButton
                                                    block={true}
                                                    icon="Download"
                                                    iconMargin={5}
                                                    inverse={true}
                                                    marginTop={false}
                                                    onClick={() => handleDownload(radio.archivo)}
                                                    size={16}
                                                    text={radio.archivoName}
                                                    type="button"
                                                />
                                            </div>
                                        </div>
                                    </div>)
                                    : <div>Sin laboratorios previos</div>
                                    }
                                </>
                                }
                            </>
                        }
                    </>
                    }
                </>
            }
        </>
    )
}
