import React from 'react';
import * as icons from 'react-bootstrap-icons';

import PropTypes from 'prop-types';

import styles from './Buttons.module.css';

export const IconButton = ({ disabled = false, icon, iconColor, marginLeft = false, marginRight = false, marginTop = true, onClick, type }) => {

  let Icon = null;
  if(icon !== null){
    Icon = icons[icon];
  }

  return (
    <button type={ type } className={"btn " + styles.customButton + " " + styles.customIconButton + " " + ((marginTop) ? " " + styles.customButtonMT : "") + ((marginLeft) ? " " + styles.customButtonML : "") + ((marginRight) ? " " + styles.customButtonMR : "")} onClick={onClick} disabled={disabled}><Icon className={styles.icon} color={iconColor} /></button>
  )
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  marginTop: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
}