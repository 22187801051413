import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const ColorInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, setFormFieldValidation, value }) => {

  const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValidation = useCallback(() => {
    if(value !== ''){
        setInputValidation({ isValid: true, msg: null });
        if(setFormFieldValidation) setFormFieldValidation(name, true);
    }else{
        setInputValidation({ isValid: false, msg: label + ' no válido' });
        if(setFormFieldValidation) setFormFieldValidation(name, false);
    }
  })

  useEffect(() => {
    if(formFieldStatus === 'checking') handleInputValidation();
  }, [formFieldStatus, handleInputValidation]);
  
  return (
    <>
        <div>
            <div className='text-center'>
              <label htmlFor={ name } className={styles.customInputLabel}>{ label }</label>
            </div>
            <input type="color" className={"form-control " + styles.customColorPicker} placeholder={ placeholder } name={ name } value={ value } onChange={ onChange } onKeyUp={handleInputValidation} required={true} />
        </div>
    </>
  )
}

ColorInput.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.string,
}