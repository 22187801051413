import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query"

import { createPaciente } from "../services/pacientenuevo.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteNuevo = () => {

    const dispatch = useDispatch();

    const createPacienteMutation = useMutation({
        mutationFn: createPaciente,
        onSuccess: (paciente, variables, context) => {
            variables.onComplete();
            variables.onSuccess(paciente._id);
            dispatch(successNotification('Paciente creado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            variables.onComplete();
            dispatch(dangerNotification(error.message));
        }
    });

    return {
        createPacienteMutation,
    }
}