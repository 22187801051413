import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { independizarPaciente } from "../services/pacienteview.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteIndependizar = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const independizarPacienteMutation = useMutation({
        mutationFn: independizarPaciente,
        onSuccess: (paciente, variables, context) => {
            queryClient.setQueryData(['paciente', paciente._id], oldData => {
                return paciente;
            });
            queryClient.setQueryData(['paciente', variables.asociadoAnterior], oldData => {
                return {
                    ...oldData,
                    pacientesAsociados: oldData.pacientesAsociados.filter(pacienteAux => String(pacienteAux._id) !== String(paciente._id))
                };
            });
            variables.onSuccess();
            dispatch(successNotification('Paciente independizado'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        independizarPacienteMutation
    }
}