import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom';

import { usePacienteAntecedentes } from './hooks/usePacienteAntecedentes';

import { PacienteAntecedentesPreview } from './components/PacienteAntecedentesPreview';
import { PacienteAntecedentesUpdate } from './components/PacienteAntecedentesUpdate';

export async function loader({ params }) {
  return params.id;
}

export const PacienteAntecedentes = () => {

  const id = useLoaderData();

  const { pacienteAntecedentesQuery: { isLoading, isError, data: pacienteAntecedentes } } = usePacienteAntecedentes(id);

  const [antecedentesUpdateStatus, setAntecedentesUpdateStatus] = useState(false);

  return (
    <>
      {(isLoading)
        ? <>Cargando...</>
        : <>
            {(isError)
            ? <>Error</>
            : <>
              {(antecedentesUpdateStatus)
              ? <PacienteAntecedentesUpdate paciente={id} pacienteAntecedentes={pacienteAntecedentes} handleCancelarEditarPacienteAntecedentes={() => setAntecedentesUpdateStatus(false)} />
              : <PacienteAntecedentesPreview pacienteAntecedentes={pacienteAntecedentes} handleEditarPacienteAntecedentes={() => setAntecedentesUpdateStatus(true)} />
              }
            </>
          }
        </>
      }
    </>
  )
}