import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const FileInput = ({ disabled = false, formFieldStatus='checked', label = undefined, name, onChange, required = false, requiredIndicator = false, reset, setFormFieldValidation, value }) => {

    const inputButton = useRef(null);
    const previewInput = useRef(null);

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    const [preview, setPreview] = useState((value !== null) ? value : '');

    const handleFileLoadTrigger = () => {
        inputButton.current?.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback((e) => {
        if(e !== undefined){
            var archivo = e.target.files[0];
            if(archivo && archivo["type"] !== "image/jpeg" && archivo["type"] !== "image/png" && archivo["type"] !== "image/jpg" && archivo["type"] !== "application/pdf"){
                setInputValidation({ isValid: false, msg: 'La archivo debe estar en formato JPG, JPEG, PNG o PDF' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                return false;
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                return true;
            }
        }else{
            if(required){
                setInputValidation({ isValid: false, msg: 'Selecciona un archivo' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                return false;
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                return true;
            }
        }
    })

    const handleChange = (e) => {
        if(handleInputValidation(e)){
            onChange({ target: { name: name, value: e.target.files[0] } });
            setPreview(e.target.files[0].name);
        }
    }

    useEffect(() => {
        setInputValidation({ isValid: null, msg: null });
        setPreview('');
    }, [reset])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
      }, [formFieldStatus, handleInputValidation]);
    
    
    return (
        <>
            <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
            <div className={"d-flex justify-content-start " + ((inputValidation.isValid == null || inputValidation.isValid) ? " " + styles.customBlockInputBasicWithButtonMargin : "")}>
                <input type="text" className={"form-control " + styles.customBlockInputBasicWithButton + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} value={preview} ref={previewInput} readOnly />
                <button type='button' className={"btn " + styles.customBlockInputButton} onClick={handleFileLoadTrigger} disabled={disabled}>Seleccionar archivo</button>
            </div>
            <div className={styles.helperErrorTextNoMargin + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
            <input className='d-none' type="file" onChange={handleChange} ref={inputButton} />
        </>
    )
}

FileInput.propTypes = {
    disabled: PropTypes.bool,
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    reset: PropTypes.number,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.any,
}