import PropTypes from 'prop-types';

import { IconButton, TextInputPreview } from "../../../components"

import { colors } from "../../../types/types"

export const AsociadoPreview = ({ asociado, independizar, index }) => {
    return (
        <>
            <div className="row d-flex justify-content-between">
                <div className="col-md-11 col-lg-3">
                    <TextInputPreview
                        label="Nombre"
                        name="nombre"
                        value={ asociado.nombre }
                    />
                </div>
                <div className="col-md-1 col-lg-3 d-flex justify-content-end">
                    <IconButton
                        icon='ArrowUpRightSquare'
                        iconColor={colors.PRIMARY}
                        marginTop={false}
                        onClick={() => independizar(index, asociado._id)}
                        type='button'
                    />
                </div>
            </div>
        </>
    )
}

AsociadoPreview.propTypes = {
    asociado: PropTypes.any.isRequired,
    independizar: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
}