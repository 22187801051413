import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { usePaciente } from './hooks/usePaciente';

import { Acordion, PrimaryButton, TextInputPreview } from '../../components';
import { AsociadoPreview } from './components/AsociadoPreview';
import { AsociadoUpdate } from './components/AsociadoUpdate';

import styles from './PacienteView.module.css';

export async function loader({ params }) {
    return params.id;
}

export const PacienteView = () => {

    const navigate = useNavigate();

    const id = useLoaderData();

    const { pacienteQuery: { isLoading, isError, data: paciente } } = usePaciente(id);

    const [pacienteAsociadoIndex, setPacienteAsociadoIndex] = useState(-1);

    const handleSetPacienteAsociadoIndex = (index) => {
        setPacienteAsociadoIndex(index);
    }

    const handleCancelarPacienteAsociadoIndex = () => {
        setPacienteAsociadoIndex(-1);
    }

    const handleCrearConsultorio = () => {
        navigate(`/paciente/nuevo/${paciente._id}`);
      }

    return (
        <>
        {(isLoading)
            ? <>Cargando...</>
            : <>
            {(isError)
                ? <>Error</>
                : <>
                    <div className="row">
                        <div className="col">
                            <div className={styles.panelSection + " " + styles.panelNoHeader}>
                                <div className="row">
                                    <div className="col-md-12 col-lg-2">
                                        <div className={styles.panel}>
                                            <TextInputPreview
                                                label="Próxima cita"
                                                name="proximaCita"
                                                value="-"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                        <div className={styles.panel}>
                                            <TextInputPreview
                                                label="Inmunoterapia actual"
                                                name="inmunoterapiaActual"
                                                value="-"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-8">
                                        <div className={styles.panelDivider}>
                                            <div className="row h-100">
                                                <div className="col h-100">
                                                    <div className={styles.panel}>
                                                        <div>App Móvil</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection + " " + styles.panelNoHeader}>
                                <div className={styles.subHeader}>Datos Generales</div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Paciente"
                                            name="paciente"
                                            value={ paciente.nombre + " " + paciente.apellidoPaterno + ((paciente.apellidoMaterno !== '' && paciente.apellidoMaterno !== undefined) ? " " + paciente.apellidoMaterno : "-") }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Fecha de nacimiento"
                                            name="fechaNacimiento"
                                            value={ paciente.fechaNacimientoFormato }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Edad"
                                            name="edad"
                                            value={ paciente.edad }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Género"
                                            name="genero"
                                            value={ paciente.genero }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Correo electrónico"
                                            name="email"
                                            value={ paciente.email }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Teléfono fijo"
                                            name="telefono"
                                            value={ (paciente.telefono !== '' && paciente.telefono !== undefined && paciente.telefono !== null) ? paciente.telefono : '-' }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Celular"
                                            name="celular"
                                            value={ paciente.celular }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Medio de contacto"
                                            name="medioContacto"
                                            value={ paciente.medioContacto }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Diagnóstico(s)"
                                            name="diagnosticos"
                                            value="-"
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Última consulta(s)"
                                            name="ultimaConsulta"
                                            value="-"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className={styles.subHeader}>Domicilio</div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Calle"
                                            name="calle"
                                            value={ (paciente.calle !== '' && paciente.calle !== undefined && paciente.calle !== null) ? paciente.calle : '-' }
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="# Exterior"
                                            name="numeroExterior"
                                            value={ (paciente.numeroExterior !== '' && paciente.numeroExterior !== undefined && paciente.numeroExterior !== null) ? paciente.numeroExterior : '-' }
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="# Interior"
                                            name="numeroInterior"
                                            value={ (paciente.numeroInterior !== '' && paciente.numeroInterior !== undefined && paciente.numeroInterior !== null) ? paciente.numeroInterior : '-' }
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Colonia"
                                            name="colonia"
                                            value={ (paciente.colonia !== '' && paciente.colonia !== undefined && paciente.colonia !== null) ? paciente.colonia : '-' }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Municipio"
                                            name="municipio"
                                            value={ (paciente.municipio !== '' && paciente.municipio !== undefined && paciente.municipio !== null) ? paciente.municipio : '-' }
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Entidad federativa"
                                            name="entidadFederativa"
                                            value={ (paciente.entidadFederativa !== '' && paciente.entidadFederativa !== undefined && paciente.entidadFederativa !== null) ? paciente.entidadFederativa : '-' }
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="País"
                                            name="pais"
                                            value={ (paciente.pais !== '' && paciente.pais !== undefined && paciente.pais !== null) ? paciente.pais : '-' }
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Código postal"
                                            name="codigoPostal"
                                            value={ (paciente.codigoPostal !== '' && paciente.codigoPostal !== undefined && paciente.codigoPostal !== null) ? paciente.codigoPostal : '-' }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Nacionalidad"
                                                            name="nacionalidad"
                                                            value={ (paciente.nacionalidad !== '' && paciente.nacionalidad !== undefined && paciente.nacionalidad !== null) ? paciente.nacionalidad : '-' }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Estado civil"
                                                            name="estadoCivil"
                                                            value={ (paciente.estadoCivil !== '' && paciente.estadoCivil !== undefined && paciente.estadoCivil !== null) ? paciente.estadoCivil : '-' }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Ocupación"
                                                            name="ocupacion"
                                                            value={ (paciente.ocupacion !== '' && paciente.ocupacion !== undefined && paciente.ocupacion !== null) ? paciente.ocupacion : '-' }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Religión"
                                                            name="religion"
                                                            value={ (paciente.religion !== '' && paciente.religion !== undefined && paciente.religion !== null) ? paciente.religion : '-' }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Origen"
                                                            name="origen"
                                                            value={ (paciente.origen !== '' && paciente.origen !== undefined && paciente.origen !== null) ? paciente.origen : '-' }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Residencia"
                                                            name="residencia"
                                                            value={ (paciente.residencia !== '' && paciente.residencia !== undefined && paciente.residencia !== null) ? paciente.residencia : '-' }
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                            header={<div className={styles.subHeader}>Otros</div>}
                                            id='pacienteViewOtrosAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                { paciente.tutores.map(tutor => 
                                                    (<div key={tutor._id}>
                                                        <div className={"row " + styles.tutorDivider}>
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Parentesco"
                                                                    name="parentesco"
                                                                    value={ tutor.parentesco }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Nombre(s)"
                                                                    name="nombre"
                                                                    value={ tutor.nombre }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Correo electrónico"
                                                                    name="email"
                                                                    value={ tutor.email }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Teléfono móvil"
                                                                    name="celular"
                                                                    value={ tutor.celular }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                ) }
                                            </>}
                                            header={<div className={styles.subHeader}>Tutores</div>}
                                            id='pacienteViewTutoresAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-3">
                                                        <TextInputPreview
                                                            label="Nombre"
                                                            name="nombre"
                                                            value={ paciente.usuario.nombre }
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-3">
                                                        <TextInputPreview
                                                            label="Correo electrónico"
                                                            name="email"
                                                            value={ paciente.usuario.email }
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                            header={<div className={styles.subHeader}>Cuenta de Usuario</div>}
                                            id='paceienteViewUsuarioAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>
                            {(typeof paciente.pacientesAsociados !== 'undefined') && <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                { paciente.pacientesAsociados.map((asociado, index) => <div key={asociado._id}>
                                                    {(pacienteAsociadoIndex === index)
                                                    ? <AsociadoUpdate
                                                        asociado={asociado}
                                                        asociadoAnterior={paciente._id}
                                                        cancelar={handleCancelarPacienteAsociadoIndex}
                                                        idAsociado={asociado._id}
                                                    />
                                                    : <AsociadoPreview
                                                        asociado={asociado}
                                                        independizar={() => handleSetPacienteAsociadoIndex(index)}
                                                        index={index}
                                                    />
                                                    }
                                                    <hr className={styles.pacientesAsociadosHr} />
                                                </div>) }
                                                <div className='d-flex justify-content-end'>
                                                    <PrimaryButton
                                                        icon="Plus"
                                                        inverse={true}
                                                        marginTop={false}
                                                        onClick={handleCrearConsultorio}
                                                        text="Agregar Paciente"
                                                        type="button"
                                                    />
                                                </div>
                                            </>}
                                            header={<div className={styles.subHeader}>Pacientes Asociados</div>}
                                            id='pacienteViewUsuarioPacientesAsociadosAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </>
            }
            </>
        }
        </>
    )
}
