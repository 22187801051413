import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { useDatosGenerales } from '../hooks/useDatosGenerales';
import { useForm, useFormValidation } from '../../../../../hooks';

import { ColorInput, LightButton, PrimaryButton, TextAreaInput } from "../../../../../components";

import styles from './../DatosGenerales.module.css';

export const DatosGeneralesUpdate = ({ datosGenerales }) => {

    const { updateDatosGeneralesMutation: { mutate: updateDatosGenerales, isPending } } = useDatosGenerales();

    const [dataStatus, setDataStatus] = useState(false);

    const { bienvenida, confirmacionCita, colorTema, onInputChange, onResetForm } = useForm({ bienvenida: datosGenerales.personalizacion.bienvenida, confirmacionCita: datosGenerales.personalizacion.confirmacionCita, colorTema: datosGenerales.personalizacion.colorTema });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'bienvenida', default: true}, {field: 'confirmacionCita', default: true}, {field: 'colorTema', default: true}]);

    const handleChange = (e) => {
        setDataStatus(true);
        onInputChange(e);
    }

    const handleCancelChange = () => {
        onResetForm();
        setDataStatus(false);
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updateDatosGenerales({ data: { bienvenida, confirmacionCita, colorTema } });
            setDataStatus(false);
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Personalización</div>
                    <div className="row">
                        <div className="col">
                            <TextAreaInput
                                formFieldStatus={ formFields['bienvenida'].status }
                                label="Mensaje de bienvenida"
                                name="bienvenida"
                                onChange={ handleChange }
                                required={ false }
                                requiredIndicator={ false }
                                setFormFieldValidation={ setFormFieldValidation }
                                value={ bienvenida }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextAreaInput
                                formFieldStatus={ formFields['confirmacionCita'].status }
                                label="Mensaje de confirmación de cita"
                                name="confirmacionCita"
                                onChange={ handleChange }
                                required={ false }
                                requiredIndicator={ false }
                                setFormFieldValidation={ setFormFieldValidation }
                                value={ confirmacionCita }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-1">
                            <ColorInput
                                formFieldStatus={ formFields['colorTema'].status }
                                label="Color de tema"
                                name="colorTema"
                                onChange={ handleChange }
                                setFormFieldValidation={ setFormFieldValidation }
                                value={ colorTema }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <div className='d-flex justify-content-end'>
                            <LightButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="X"
                                marginTop={false}
                                marginRight={true}
                                onClick={handleCancelChange}
                                text="Cancelar"
                                type="button"
                            />
                            <PrimaryButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="Check"
                                marginTop={false}
                                text="Guardar"
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

DatosGeneralesUpdate.propTypes = {
    datosGenerales: PropTypes.object.isRequired,
}