import { useState } from 'react';
import { useLoaderData, useOutletContext } from 'react-router-dom';

import { usePaciente } from './hooks/usePaciente';

import { PacienteEditarForm } from './components/PacienteEditarForm';

export async function loader({ params }) {
  return params.id;
}

export const PacienteEditar = () => {

  const id = useLoaderData();

  const { pacienteQuery: { isLoading, isError, data: paciente } } = usePaciente(id);

  return (
    <>
      {(isLoading)
        ? <>Cargando...</>
        : <>
          {(isError)
            ? <>Error</>
            : <>
              <PacienteEditarForm paciente={paciente} />
            </>
          }
        </>
      }
    </>
  )
}
