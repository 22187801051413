import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getPacienteRadiologia, createPacienteRadiologia } from "../services/radiologia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteRadiologia = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const pacienteRadiologiaQuery = useQuery({
        queryKey: ['paciente/radiologia', id],
        queryFn: () => getPacienteRadiologia(id),
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const createRadiologiaMutation = useMutation({
        mutationFn: createPacienteRadiologia,
        onSuccess: (radiologia, variables, context) => {
            queryClient.setQueryData(['paciente/radiologia', id], oldData => {
                return [
                    ...oldData,
                    radiologia
                ];
            });
            variables.success();
            dispatch(successNotification('Radiología creada'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        pacienteRadiologiaQuery,
        createRadiologiaMutation,
    }
}