import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from './FormFields.module.css';

export const ReactAutocompleteInput = ({ defaultValue, formFieldStatus='checked', label = '', name, onChange, required = false, requiredIndicator = false, reset, setFormFieldValidation, value, values}) => {

  const css = `
        .select__control--is-focused{
            border-color: #86b7fe !important;
            box-shadow: none !important;
        }
    `;

    const [initialFlag, setInitialFlag] = useState(false);

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(value !== ''){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
            setInitialFlag(true);
        }else{
            if(required && initialFlag){
                setInputValidation({ isValid: false, msg: 'Selecciona un elemento de la lista' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                setInitialFlag(true);
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                setInitialFlag(true);
            }
        }
    })

    const handleOnChange = (value) => {
        onChange({ target: { name: name, value: value.value } });
    }

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        setInputValidation({ isValid: null, msg: null });
        if(required){
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }else{
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
      }, [formFieldStatus, handleInputValidation]);
  
    return (
        <>
            <style>{ css }</style>
            {(label !== '') && <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <Select
                className={"basic-multi-select " + styles.basicMultiSelect}
                classNamePrefix="select"
                defaultValue={defaultValue}
                isSearchable
                name={name}
                onChange={handleOnChange}
                options={values}
                placeholder=""
            />
            <div className={styles.helperErrorTextReactSelect + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

ReactAutocompleteInput.propTypes = {
    defaultValue: PropTypes.array,
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    reset: PropTypes.number,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
}