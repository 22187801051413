import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getPacienteLaboratorio, createPacienteLaboratorio } from "../services/laboratorio.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteLaboratorio = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const pacienteLaboratorioQuery = useQuery({
        queryKey: ['paciente/laboratorio', id],
        queryFn: () => getPacienteLaboratorio(id),
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const createLaboratorioMutation = useMutation({
        mutationFn: createPacienteLaboratorio,
        onSuccess: (laboratorio, variables, context) => {
            queryClient.setQueryData(['paciente/laboratorio', id], oldData => {
                return [
                    ...oldData,
                    laboratorio
                ];
            });
            variables.success();
            dispatch(successNotification('Laboratorio creado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        pacienteLaboratorioQuery,
        createLaboratorioMutation,
    }
}