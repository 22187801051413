const colors = {

    PRIMARY: '#4D96D9',
    INPUT_FEEDBACK: '#4A4A4A',
    DANGER: '#F34336',
    PANEL_BORDER: '#C7D4DD',

}

module.exports = {
    colors
}